import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';

import './work.css';

class Experience extends Component {
  render() {
    return (
      <div className="experience section">
        <h3 className="page-title">Work</h3>

        <Row>
          <Col sm={{ size: 6, offset: 0 }} className="role">
            <h4>Lead Product Engineer</h4>
            <h4><a href="https://bambooapp.ie" target="_blank" rel="noopener noreferrer">Bamboo</a></h4>
            <p>Feb. 2018 - Present</p>
          </Col>
          <Col sm={{ size: 6, offset: 0 }} className="role">
            <h4>Software Engineer &amp; Designer</h4>
            <h4><a href="http://www.plynk.me" target="_blank" rel="noopener noreferrer">Plynk</a></h4>
            <p>June 2017 - Present</p>
            <p>Joined Plynk as a result of my team &amp; I at iDly Systems negotiating a deal to join Plynk full time, where we're now working to fulfil Plynk's mission to become a verb in every language.</p>
          </Col>
        </Row>

        <Row>
          <Col sm={{ size: 6, offset: 0 }} className="role">
            <h4>Web Developer &amp; Head of Design</h4>
            <h4><a href="http://www.idlysystems.com" target="_blank" rel="noopener noreferrer">iDly Systems</a></h4>
            <p>Nov. 2016 - June 2017</p>
            <p>Designed &amp; developed our web frontend while also focusing on our overall design strategy for web, mobile, and branding.</p>
            <p>After 18 months, thousands of happy users and a number of commercial opportunities fulfilled myself and my team at iDly joined Plynk just after their Series A financing. We're now working to fulfil Plynk's mission to become a verb in every language.</p>
          </Col>
          <Col sm={{ size: 6, offset: 0 }} className="role">
            <h4>Frontend Engineering Intern</h4>
            <h4><a href="http://www.minicorp.ie" target="_blank" rel="noopener noreferrer">MiniCorp</a></h4>
            <p>July 2016 - Sept. 2016</p>
            <p>Implemented frontend designs/refactors for our clients (both launched and in-development), supported our existing clients, worked on feature requests from our clients, and communicated progress with said clients.</p>
          </Col>
        </Row>

        <Row>
          <Col sm={{ size: 6, offset: 0 }} className="role">
            <h4>Chairperson</h4>
            <h4><a href="http://ducss.ie" target="_blank" rel="noopener noreferrer" >DUCSS</a></h4>
            <p>March 2016 - March 2017</p>
            <p>Responsible for the general running of the Computer Science society & representing us to external parties. Negotiated our first major corporate sponsorship, built & launched DUCSS Internships, one of five societies awarded an Equality Award for working on Gender Equality in Computer Science</p>
          </Col>
          <Col sm={{ size: 6, offset: 0 }} className="role">
            <h4>Software Engineer</h4>
            <h4><a href="http://www.opsh.com" target="_blank" rel="noopener noreferrer" >Opsh</a></h4>
            <p>Nov. 2015 - June 2016</p>
            <p>Planned &amp; developed backend software to support our e-commerce platform, and developing / upgrading internal tools. Worked to improve reliability and performance in updating store listings, their stock, and new listings.</p>
          </Col>
        </Row>

        <Row>
          <Col sm={{ size: 6, offset: 0 }} className="role">
            <h4>Research &amp; Development Intern</h4>
            <h4><a href="http://www.opsh.com" target="_blank" rel="noopener noreferrer" >Opsh</a></h4>
            <p>Aug. 2015 - Sept. 2015</p>
            <p>Developed internal tools and full stack systems to support our e-commerce platform.</p>
          </Col>
        </Row>
      </div >
    );
  }
}

export default Experience;
