import React, { Component, Fragment } from 'react';
import { Row, Col } from 'reactstrap';
import { Link } from 'react-router-dom';

import "./ryeen-day.css";

class RyeenDays extends Component {
  render() {
    return (
      <Fragment>
        <Row>
          <Col sm={{ size: 2, offset: 3 }} className="text-center ryeen-day">
            <Link className="invite-plan" to={'/ryeen-day/2018'}>
              <div className="card ryeen-list-card">
                <h3 className="ryeen-day-18-style">2018?</h3>
              </div>
            </Link>
          </Col>

          <Col sm={{ size: 2, offset: 0 }} className="text-center ryeen-day">
            <Link className="invite-plan" to={'/ryeen-day/2017'}>
              <div className="card ryeen-list-card">
                <h3 className="ryeen-day-17-style">2017</h3>
              </div>
            </Link>
          </Col>

          <Col sm={{ size: 2, offset: 0 }} className="text-center ryeen-day">
            <Link className="invite-plan" to={'/ryeen-day/2016'}>
              <div className="card ryeen-list-card">
                <h3 className="ryeen-day-16-style">2016</h3>
              </div>
            </Link>
          </Col>
        </Row >
      </Fragment >
    );
  }
}

export default RyeenDays;
