import React, { Component, Fragment } from 'react';

import ryeen from "assets/images/ryeen-day/2016/ryeen.JPG";
import "./ryeen-day-16.css";

import applauseAudio from "assets/images/ryeen-day/2016/ryeen.mp3";

class RyeenDay2016 extends Component {
    constructor(props) {
        super(props);
        this.state = { play: false };

        this.applause = new Audio(applauseAudio);
        this.applause.loop = true;
    }

    togglePlay = () => {
        this.setState({ play: !this.state.play });
        const shouldPlay = !this.state.play;
        shouldPlay ? this.applause.play() : this.applause.pause();
    }

    render() {
        return (
            <Fragment>
                <img alt="Ryan holding guitar" src={ryeen} />
                <h1 style={{ left: "941px", top: "111px" }}>Happy Birthday Ryeen</h1>
                <button style={{ position: "absolute", left: "941px", top: "180px" }} onClick={this.togglePlay}>{this.state.play ? 'Pause music' : 'Play music'}</button>
            </Fragment>
        );
    }
}

export default RyeenDay2016;
