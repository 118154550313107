import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';

import './recent-project.css';

class RecentProject extends Component {
  render() {
    return (
      <div className="projects">
        <h3 className="page-title">Latest projects</h3>
        <br />

        <Row>
          <Col sm={{ size: 6, offset: 0 }} className="project-thumbnail">
            <a href="https://ducss.ie/internships" target="_blank" rel="noopener noreferrer">
              <img src="./assets/images/projects/ducss-internships/thumbnail.png" alt="Website of Dublin University Computer Science Society (Trinity College Dublin)'s internship website featuring various internship roles" />
              <h5>DUCSS Internships</h5>
            </a>
          </Col>
          <Col sm={{ size: 6, offset: 0 }} className="project-thumbnail">
            <a href="https://github.com/PatrickOBoyle/slides/tree/master/building-chrome-extensions" target="_blank" rel="noopener noreferrer">
              <img src="./assets/images/projects/building-chrome-extensions/thumbnail.png" alt="Opening slide to presentation titled 'Building Chrome extensions' on 22 October 2015 for Trinity College, Netsoc by Patrick O Boyle" />
              <h5>Building Chrome Extensions</h5>
            </a>
          </Col>
        </Row>
      </div>
    );
  }
}

export default RecentProject;
