import React, { Component } from 'react';
import moment from 'moment';

import { Row, Col } from 'reactstrap';
import './blogs.css';
const feedparser = require('feedparser-promised');


class Blogs extends Component {
  constructor() {
    super();
    this.state = {
      latest: [],
      articles: []
    }
    this.getArticles();
  }

  strip(html) {
    var tmp = document.createElement("DIV");
    tmp.innerHTML = html;
    return tmp.textContent || tmp.innerText || "";
  }

  render() {
    const listItems = this.state.articles.map((article) => (
      <Col sm={{ size: 6, offset: 0 }} key={article.guid}>
        <a href={article.link} className="blog-post" target="_blank" rel="noopener noreferrer">
          <div>
            <h3>{article.title}</h3>
            <p>{this.strip(article.description).substring(0, 54)}...</p>
          </div>
          <div className="post-tag">
            🛠
          </div>
        </a>
      </Col>
    ));

    return (
      <div className="blog">
        <h3 className="page-title">Latest blogs</h3>
        <Row>
          {listItems}
        </Row>

        <p>
          <a href="https://medium.com/@patrick_oboyle/" target="_blank" rel="noopener noreferrer">Read more</a>
        </p>
      </div>
    );
  }

  getArticles() {
    this.getFeed();
  }

  async getFeed() {
    const url = 'https://cors-anywhere.herokuapp.com/https://medium.com/feed/@patrick_oboyle';
    let feed = await feedparser.parse(url);
    this.state.latest = feed[0];
    this.setState({ "latest": feed[0] })

    let articles = [];
    feed.forEach(article => {
      articles.push(article)
    });

    this.setState({ "articles": articles });
  }

  // convert Medium's date string to form of 8th Feb 2018
  getFormattedPubDate(date) {
    return moment(date).format("Do MMM YYYY");
  }
}

export default Blogs;
