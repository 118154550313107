import React, { Component } from 'react';

import { Switch, BrowserRouter as Router, Route } from 'react-router-dom';

import MainLayout from './layouts/MainLayout/MainLayout';

import Home from './pages/home/home';
import Projects from './pages/projects/projects';
import About from './pages/about/about';
import Work from './pages/work/work';


import RyeenDays from './pages/ryeen-day/index';

import RyeenLayout2016 from './layouts/RyeenDayLayouts/2016/RyeenLayout2016.js';
import RyeenDay2016 from './pages/ryeen-day/2016/ryeen-day-2016.js';

import RyeenLayout2017 from './layouts/RyeenDayLayouts/2017/RyeenLayout2017';
import RyeenDay2017 from './pages/ryeen-day/2017/ryeen-day-2017';

import RyeenLayout2018 from './layouts/RyeenDayLayouts/2018/RyeenLayout2018';
import RyeenDay2018 from './pages/ryeen-day/2018/ryeen-day-2018';

import './App.css';

class App extends Component {
  render() {
    const AppRoute = ({ component: Component, layout: Layout, ...rest }) => (
      <Route {...rest} render={props => (
        <Layout>
          <Component {...props} />
        </Layout>
      )} />
    );

    return (
      <Router>
        <Switch>
          <AppRoute exact path="/" layout={MainLayout} component={Home} />
          <AppRoute exact path="/projects" layout={MainLayout} component={Projects} />
          <AppRoute exact path="/work" layout={MainLayout} component={Work} />
          <AppRoute exact path="/about" layout={MainLayout} component={About} />

          <AppRoute exact path="/ryeen-day" layout={MainLayout} component={RyeenDays} />
          <AppRoute exact path="/ryeen-day/2016" layout={RyeenLayout2016} component={RyeenDay2016} />
          <AppRoute exact path="/ryeen-day/2017" layout={RyeenLayout2017} component={RyeenDay2017} />
          <AppRoute exact path="/ryeen-day/2018" layout={RyeenLayout2018} component={RyeenDay2018} />
        </Switch>
      </Router>
    );
  }
}

export default App;
