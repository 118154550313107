import React, { Component } from 'react';

import './footer.css';

class Footer extends Component {
  render() {
    return (
      <footer>
        <div className="social section" id="social">
          <ul>
            <li><a href="https://www.instagram.com/patrickoboyler/" title="My Instagram" target="_blank" rel="noopener noreferrer"><p className="icon socicon-instagram"></p></a></li>
            <li><a href="https://github.com/PatrickOBoyle" title="My GitHub" target="_blank" rel="noopener noreferrer" ><p className="icon socicon-github"></p></a></li>
            <li><a href="https://twitter.com/Patrick_OBoyle" title="My Twitter" target="_blank" rel="noopener noreferrer" ><p className="icon socicon-twitter"></p></a></li>
            <li><a href="https://ie.linkedin.com/in/patrickoboyler" title="My LinkedIn" target="_blank" rel="noopener noreferrer" ><p className="icon socicon-linkedin"></p></a></li>
           </ul>
         </div>
         <p className="legals">
           Social icons from <a href="http://www.socicon.com" target="_blank" rel="noopener noreferrer"  alt="Free social icons font" title="the social icons font">socicon</a>
           <br/>
           © Patrick O'Boyle 2018
           <br/>
         </p>
     </footer>
    );
  }
}

export default Footer;
