import React, { Component } from 'react';
import "./ryeenLayout18.css";

class RyeenLayout2018 extends Component {
  render() {
    return (
      <div className="ryeen18-root">
        {this.props.children}
      </div>
    );
  }
}

export default RyeenLayout2018;