import React, { Component } from 'react';
import { Row } from 'reactstrap';

import Blogs from 'components/blogs/blogs';
import RecentProject from 'components/recent-project/recent-project';

import mePic from 'assets/images/me.jpg';

import './home.css';

class Home extends Component {
  render() {
    return (
      <Row>
        <div className="section">
          <div className="hello">
            <div className="me bubble">
              <h3>Hi, I make things</h3>
              <p>
                Currently Lead Product Engineer @ <a href="https://bambooapp.ie" target="_blank" rel="noopener noreferrer">Bamboo</a>.
              <br />
                Working to help you order, pay &amp; skip the queue
              </p>
              <img className="me-pic" alt="My face and upper torso against a brick wall. Wearing a salmon coloured shirt over a maroon t-shirt" src={mePic} />
            </div>
          </div>

          <Blogs />

          <RecentProject />
        </div>
      </Row>
    );
  }
}

export default Home;
