import React, { Component } from 'react';
import "./ryeenLayout17.css";

class RyeenLayout2017 extends Component {
  render() {
    return (
      <div className="ryeen17-root">
        <div className="container-fluid">
          {this.props.children}
        </div>
        <div className="made">
          <h5 className="text-center">
            <a href="https://youtu.be/dQw4w9WgXcQ" target="_blank" rel="noopener noreferrer">{"Made with <3 in Dublin"}</a>
          </h5>
        </div>
      </div>
    );
  }
}

export default RyeenLayout2017;