import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import { Navbar, Nav, NavItem } from 'reactstrap';

import './nav.css';

class GenericNav extends Component {
  constructor(props, context) {
    super(props, context);

    this.toggle = this.toggle.bind(this);
    this.state = {
      isOpen: false
    };
  }

  toggle() {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }

  render() {
    return (
      <Navbar color="faded" light expand="md" className="section">
        <span>
          <NavLink
            exact
            to="/"
            activeClassName="logo-title-active"
            className="logo-title"
          >pb</NavLink>
        </span>

        <Nav className="ml-auto">
          <NavLink
            exact
            to="/work"
            activeClassName="router-link-active"
            className="router-link"
          >Work</NavLink>
          <NavItem>
            <a href="https://medium.com/@patrick_oboyle/" target="_blank" rel="noopener noreferrer" className="router-link">Blog</a>
          </NavItem>
        </Nav>
      </Navbar>
    );
  }
}

export default GenericNav;
