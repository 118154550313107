import React, { Component } from 'react';

import GenericNav from 'components/nav/nav';
import Footer from 'components/footer/footer'

import { Row } from 'reactstrap';

import './MainLayout.css';

class MainLayout extends Component {
  render() {
    return (
      <div className="container-fluid">
        <Row>
          <GenericNav />
        </Row>

        {this.props.children}

        <Footer />
      </div>
    );
  }
}
export default MainLayout;