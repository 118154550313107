import React, { Component, Fragment } from 'react';

import './ryeen17.css';
import './responsive.css';
import './fw.css';

class RyeenDay2017 extends Component {
    render() {
        return (
            <Fragment>
                <div className="row">
                    <div className="col-md-12">
                        <div className="ryan-hero box fw">
                            <div className="before"></div>
                            <div className="after"></div>
                            <div className="line"></div>
                            <h1 className="text-left">
                                Happy
                                <br />
                                birthday
                                <br />
                                Ryan
                            </h1>
                        </div>

                        <div className="listening box">
                            <h2>Sick beats</h2>
                            <div className="col-12">
                                <div className="row text-center">
                                    <div className="col-md-4">
                                        <div><h3>Your style</h3></div>
                                        <iframe title="Your style spotify playlist" src="https://open.spotify.com/embed/user/patrickoboyle/playlist/1ubJC4m0zwYrrCYcPFRP35" width="300" height="380" frameBorder="0" allowtransparency="true"></iframe>
                                    </div>

                                    <div className="col-md-4">
                                        <h3>Patrick's recs</h3>
                                        <iframe title="Patrick's recs spotify playlist" src="https://open.spotify.com/embed/user/patrickoboyle/playlist/1AWzwvJ0iAp9tzW7XtvbPk" width="300" height="380" frameBorder="0" allowtransparency="true"></iframe>
                                    </div>

                                    <div className="col-md-4">
                                        <h3>Give it a shot</h3>
                                        <iframe title="Give it a shot spotify playlist" src="https://open.spotify.com/embed/user/patrickoboyle/playlist/0vXB2JzsJHCaDE9LifQEaF" width="300" height="380" frameBorder="0" allowtransparency="true"></iframe>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="year-in-review box">
                            <h2>Year in review</h2>

                            <div className="months-box col-md-10 offset-md-1">
                                <div className="row">
                                    <div className="col-sm-8 offset-sm-2 col-10 offset-1 col-md-3 offset-md-1 month bg-cover bg-jan">
                                        <div className="text-box">
                                            <h3>Trump Inauguration</h3>
                                            <p>January</p>
                                        </div>
                                    </div>

                                    <div className="col-sm-8 offset-sm-2 col-10 offset-1 col-md-3 offset-md-1 month bg-cover bg-feb">
                                        <div className="text-box">
                                            <h3>Michael Flynn resigns</h3>
                                            <p>February</p>
                                        </div>
                                    </div>

                                    <div className="col-sm-8 offset-sm-2 col-10 offset-1 col-md-3 offset-md-1 month bg-cover bg-march">
                                        <div className="text-box">
                                            <h3>FBI investigating Trump</h3>
                                            <p>March</p>
                                        </div>
                                    </div>

                                    <div className="col-sm-8 offset-sm-2 col-10 offset-1 col-md-3 offset-md-1 month bg-cover bg-april">
                                        <div className="text-box">
                                            <h3>May announces June election</h3>
                                            <p>April</p>
                                        </div>
                                    </div>

                                    <div className="col-sm-8 offset-sm-2 col-10 offset-1 col-md-3 offset-md-1 month bg-cover bg-may">
                                        <div className="text-box">
                                            <h3>Enda Kenny steps down as Taoiseach</h3>
                                            <p>May</p>
                                        </div>
                                    </div>

                                    <div className="col-sm-8 offset-sm-2 col-10 offset-1 col-md-3 offset-md-1 month bg-cover bg-june">
                                        <div className="text-box">
                                            <h3>UK Election goes sour for May</h3>
                                            <p>June</p>
                                        </div>
                                    </div>

                                    <div className="col-10 offset-1 col-sm-8 offset-sm-2  col-md-3 offset-md-1 month bg-cover bg-july">
                                        <div className="text-box">
                                            <h3>Grenfell Tower fire (London)</h3>
                                            <p>July</p>
                                        </div>
                                    </div>

                                    <div className="col-sm-8 offset-sm-2 col-10 offset-1 col-md-3 offset-md-1 month bg-cover bg-aug">
                                        <div className="text-box">
                                            <h3>Solar eclipse</h3>
                                            <p>August</p>
                                        </div>
                                    </div>

                                    <div className="col-sm-8 offset-sm-2 col-10 offset-1 col-md-3 offset-md-1 month bg-cover bg-sept">
                                        <div className="text-box">
                                            <h3>Catalonia prepares for referendum</h3>
                                            <p>September</p>
                                        </div>
                                    </div>

                                    <div className="col-sm-8 offset-sm-2 col-10 offset-1 col-md-3 offset-md-1 month bg-cover bg-oct">
                                        <div className="text-box">
                                            <h3>Hurricane Ophelia</h3>
                                            <p>October</p>
                                        </div>
                                    </div>

                                    <div className="col-sm-8 offset-sm-2 col-10 offset-1 col-md-3 offset-md-1 month bg-cover bg-nov">
                                        <div className="text-box">
                                            <h3>Australia approves same-sex marriage</h3>
                                            <p>November</p>
                                        </div>
                                    </div>

                                    <div className="col-sm-8 offset-sm-2 col-10 offset-1 col-md-3 offset-md-1 month bg-cover bg-dec">
                                        <div className="text-box">
                                            <h3>Bitcoin value soars</h3>
                                            <p>December</p>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>

                        <div className="places box">
                            <h2>Private Ryans &amp; Where to Find Them</h2>

                            <ul>
                                <li>
                                    <a target="_blank" rel="noopener noreferrer" href="https://www.google.ie/maps/place/Accents+Coffee+%26+Tea+Lounge/@53.3412842,-6.2669137,17z/data=!3m1!4b1!4m5!3m4!1s0x48670e9db848291f:0xb7ebbca6c8751886!8m2!3d53.341281!4d-6.264725">Accents</a> - <span>Coffee</span>
                                </li>
                                <li>
                                    <a target="_blank" rel="noopener noreferrer" href="https://www.google.ie/maps/place/Oolong+Flower+Power/@53.3414479,-6.2646084,18.22z/data=!4m12!1m6!3m5!1s0x48670e82ccaed2b7:0xd1d25c00d489c2a6!2sJoy+of+Chá!8m2!3d53.3452144!4d-6.2660502!3m4!1s0x0:0x4d486b90f39f7519!8m2!3d53.3412289!4d-6.2645862">Oolong Flower Power</a> - <span>Tea</span>
                                </li>
                                <li>
                                    <a target="_blank" rel="noopener noreferrer" href="https://www.google.ie/maps/place/Joy+of+Chá/@53.3452176,-6.2682389,17z/data=!3m1!4b1!4m5!3m4!1s0x48670e82ccaed2b7:0xd1d25c00d489c2a6!8m2!3d53.3452144!4d-6.2660502">Joy of Chá</a> - <span>Tea</span>
                                </li>
                                <li>
                                    <a target="_blank" rel="noopener noreferrer" href="https://www.google.ie/maps/place/Caffe+Amore+by+Luli+Montana/@53.3421728,-6.2670622,17z/data=!3m1!4b1!4m5!3m4!1s0x48670e9daf2296b5:0xca8f60f2c5a5f683!8m2!3d53.3421696!4d-6.2648735">Caffe Amore</a> - <span>Lunch</span>
                                </li>
                                <li>
                                    <a target="_blank" rel="noopener noreferrer" href="https://www.google.ie/maps/place/Two+Pups/@53.3393563,-6.2739675,19z/data=!3m1!4b1!4m5!3m4!1s0x48670c2153499727:0x70f53835484e9209!8m2!3d53.3393555!4d-6.2734203">Two Pups</a> - <span>Coffee / Brunch</span>
                                </li>
                                <li>
                                    <a target="_blank" rel="noopener noreferrer" href="https://www.google.ie/maps/place/3fe/@53.3404651,-6.246526,15.89z/data=!4m5!3m4!1s0x0:0xd007a98bdb9452c!8m2!3d53.339959!4d-6.2419684">3fe</a> - <span>Coffee / Brunch</span>
                                </li>
                                <li>
                                    <a target="_blank" rel="noopener noreferrer" href="https://www.google.ie/maps/place/Token/@53.3473615,-6.2720556,15.62z/data=!4m5!3m4!1s0x48670c2e50904e1f:0x51137aabca344eb8!8m2!3d53.3475893!4d-6.2802154">Token</a> - <span>Dinner / Arcade</span>
                                </li>
                                <li>
                                    <a target="_blank" rel="noopener noreferrer" href="https://www.google.ie/maps/place/Peruke+%26+Periwig/@53.3362407,-6.2587573,15.49z/data=!4m5!3m4!1s0x48670e9958b76a6f:0x674df2ca6bb3447!8m2!3d53.3400983!4d-6.2587738">Peruke &amp; Periwig (P&amp;P)</a> - <span>Cocktails / Dinner</span>
                                </li>
                                <li>
                                    <a target="_blank" rel="noopener noreferrer" href="https://www.google.ie/maps/place/La+Cave+Wine+Bar+and+Restaurant/@53.3380769,-6.2587573,16.33z/data=!4m5!3m4!1s0x0:0x46f4a8b936759a62!8m2!3d53.3410592!4d-6.2596992">La Cave</a> - <span>Wine bar / Dinner</span>
                                </li>
                                <li>
                                    <a target="_blank" rel="noopener noreferrer" href="https://www.google.ie/maps/place/Saba/@53.3395375,-6.260431,16z/data=!3m1!5s0x48670e9c0cb09de7:0xb825fcecea853d4c!4m12!1m6!3m5!1s0x0:0x46f4a8b936759a62!2sLa+Cave+Wine+Bar+and+Restaurant!8m2!3d53.3410592!4d-6.2596992!3m4!1s0x0:0xd136e25cd30e05a9!8m2!3d53.3411961!4d-6.2618825">Saba</a> - <span>Vietnamiese restaurant</span>
                                </li>
                                <li>
                                    <a target="_blank" rel="noopener noreferrer" href="https://www.google.ie/maps/place/The+Port+House/@53.3419305,-6.2629242,18.1z/data=!4m5!3m4!1s0x0:0xc669af0d29ef1d8b!8m2!3d53.3428199!4d-6.2619281">The Port House</a> - <span>Wine / Tapas</span>
                                </li>
                                <li>
                                    <a target="_blank" rel="noopener noreferrer" href="https://www.google.ie/maps/place/Kaph/@53.3423227,-6.2629663,18.52z/data=!4m5!3m4!1s0x48670e9c5804549b:0x2692ce64c6fc9cbd!8m2!3d53.3425941!4d-6.2632397">Kaph</a> - <span>Coffee</span>
                                </li>

                                <li>
                                    <a target="_blank" rel="noopener noreferrer" href="https://www.google.ie/maps/place/Coppinger+Row/@53.3419058,-6.2624463,19z/data=!3m1!4b1!4m5!3m4!1s0x48670e9c67e762b7:0xce343c04446338c9!8m2!3d53.341905!4d-6.2618991">Coppinger Row</a> - <span>Cocktails / Gin / Food</span>
                                </li>

                                <li>
                                    <a target="_blank" rel="noopener noreferrer" href="https://www.google.ie/maps/place/Pickle+Restaurant/@53.3339902,-6.2672817,17z/data=!3m1!4b1!4m5!3m4!1s0x48670ea004f3572f:0x7c2d80cb14880611!8m2!3d53.333987!4d-6.265093">Pickle</a> - <span>Best Indian in Dublin</span>
                                </li>

                                <li>Anywhere on
                                    <ol>
                                        <li>
                                            <a target="_blank" rel="noopener noreferrer" href="https://www.google.ie/maps/place/William+St+S,+Dublin/@53.3418784,-6.2649864,17z/data=!4m5!3m4!1s0x48670e9c435fd04d:0xcbe9f1e45b6f9a23!8m2!3d53.3419983!4d-6.2626613">South William Street</a>
                                        </li>
                                        <li>
                                            <a target="_blank" rel="noopener noreferrer" href="https://www.google.ie/maps/place/2+Drury+St,+Dublin/@53.3418784,-6.2649864,17z/data=!3m1!4b1!4m5!3m4!1s0x48670e9c5197797b:0x4f427e2f59e69b01!8m2!3d53.3418764!4d-6.263625">Drury Streert</a>
                                        </li>
                                    </ol>
                                </li>
                            </ul>
                        </div>

                        <div className="outro box">
                            <h2>Happy 22<sup>nd</sup> birthday</h2>
                            <h3>Enjoy <a href="https://youtu.be/AgFeZr5ptV8" target="_blank" rel="noopener noreferrer">feelin' 22</a>, but <a href="https://youtu.be/nHc288IPFzk" target="_blank" rel="noopener noreferrer">don't forget the classics</a></h3>
                        </div>
                    </div>
                </div>
            </Fragment>
        );
    }
}

export default RyeenDay2017;
