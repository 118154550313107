import React, { Component } from 'react';

import "./ryeenLayout16.css";

class RyeenLayout2016 extends Component {
    render() {
        return (
            <div className="ryeen16-root">
                <div className="container-fluid">
                    {this.props.children}
                </div>
            </div>
        );
    }
}

export default RyeenLayout2016;