import React, { Component } from "react";
import { Row, Col } from "reactstrap";
import { Link } from "react-router-dom";

import "./ryeen-day-2018.css";

class RyeenDay2018 extends Component {
  render() {
    return (
      <table className="ryeen18-table">
        <tr>
          <td className="item hero text-center">
            <Row>
              <Col sm={{ size: 4, offset: 0 }}>
                <h2 className="text-left">
                  Happy <br />
                  birthday <br />
                  Ryan
                </h2>
              </Col>
              <Col sm={{ size: 4, offset: 2 }}>
                <h4 className="text-left">
                  We've known eachother for just over 10 years now, so no better
                  time to get all nostalgic and see what's happened over the
                  years. <br />
                  <br />
                  Happy birthday buddy, enjoy
                </h4>
              </Col>
            </Row>
          </td>
          <td className="item">
            <h2 className="text-left">2008</h2>
            <Row>
              <Col sm={{ size: 4, offset: 0 }}>
                <h3>Top music:</h3>
                <ol>
                  <li>
                    <iframe
                      src="https://open.spotify.com/embed/track/0CAfXk7DXMnon4gLudAp7J"
                      width="300"
                      height="80"
                      frameborder="0"
                      allowtransparency="true"
                      allow="encrypted-media"
                    />
                  </li>
                  <li>
                    <iframe
                      src="https://open.spotify.com/embed/track/7wZUrN8oemZfsEd1CGkbXE"
                      width="300"
                      height="80"
                      frameborder="0"
                      allowtransparency="true"
                      allow="encrypted-media"
                    />
                  </li>
                  <li>
                    <iframe
                      src="https://open.spotify.com/embed/track/0JEqGkvUiMTQmFY6sgL9kg"
                      width="300"
                      height="80"
                      frameborder="0"
                      allowtransparency="true"
                      allow="encrypted-media"
                    />
                  </li>
                </ol>
              </Col>
              <Col sm={{ size: 4, offset: 0 }} className="role">
                <h3>News:</h3>
                <ul>
                  <li>Obama elected US President</li>
                  <li>Putin swaps from PM to President</li>
                  <li>China hosts summer olympics in Beijing</li>
                  <li>The Great Recession happened</li>
                </ul>
              </Col>
              <Col sm={{ size: 4, offset: 0 }} className="role">
                <h3>Life:</h3>
                <ul>
                  <li>Finished national school</li>
                  <li>Started in the Convent</li>
                  <li>We met</li>
                </ul>
              </Col>
            </Row>
          </td>
          <td className="item">
            <h2 className="text-left">2009</h2>
            <Row>
              <Col sm={{ size: 4, offset: 0 }}>
                <h3>Top music:</h3>
                <ol>
                  <li>
                    <iframe
                      src="https://open.spotify.com/embed/track/3oDFtOhcN08qeDPAK6MEQG"
                      width="300"
                      height="80"
                      frameborder="0"
                      allowtransparency="true"
                      allow="encrypted-media"
                    />
                  </li>
                  <li>
                    <iframe
                      src="https://open.spotify.com/embed/track/1QV6tiMFM6fSOKOGLMHYYg"
                      width="300"
                      height="80"
                      frameborder="0"
                      allowtransparency="true"
                      allow="encrypted-media"
                    />
                  </li>
                  <li>
                    <iframe
                      src="https://open.spotify.com/embed/track/1dzQoRqT5ucxXVaAhTcT0J"
                      width="300"
                      height="80"
                      frameborder="0"
                      allowtransparency="true"
                      allow="encrypted-media"
                    />
                  </li>
                </ol>
              </Col>
              <Col sm={{ size: 4, offset: 0 }} className="role">
                <h3>News:</h3>
                <ul>
                  <li>Michael Jackson dies</li>
                  <li>Obama begins presidential term</li>
                  <li>Swine flu was a thing</li>
                </ul>
              </Col>
              <Col sm={{ size: 4, offset: 0 }} className="role">
                <h3>Life:</h3>
                <ul>
                  <li>We got to know eachother</li>
                  <li>Science class was never the same</li>
                </ul>
              </Col>
            </Row>
          </td>
          <td className="item">
            <h2 className="text-left">2010</h2>
            <Row>
              <Col sm={{ size: 4, offset: 0 }}>
                <h3>Top music:</h3>
                <ol>
                  <li>
                    <iframe
                      src="https://open.spotify.com/embed/track/6nYoTBmGFNgfTyRC8x1Fvp"
                      width="300"
                      height="80"
                      frameborder="0"
                      allowtransparency="true"
                      allow="encrypted-media"
                    />
                  </li>
                  <li>
                    <iframe
                      src="https://open.spotify.com/embed/track/7h70RTLbnhDyPGXjl3iEhh"
                      width="300"
                      height="80"
                      frameborder="0"
                      allowtransparency="true"
                      allow="encrypted-media"
                    />
                  </li>
                  <li>
                    <iframe
                      src="https://open.spotify.com/embed/track/4HlFJV71xXKIGcU3kRyttv"
                      width="300"
                      height="80"
                      frameborder="0"
                      allowtransparency="true"
                      allow="encrypted-media"
                    />
                  </li>
                </ol>
              </Col>
              <Col sm={{ size: 4, offset: 0 }} className="role">
                <h3>News:</h3>
                <ul>
                  <li>Chilean miners trapped (in a mine)</li>
                  <li>Iceland's volcano eruption blankets Europe in ash</li>
                  <li>
                    Gordon Brown (Labour) resigns as UK PM, David Cameron
                    (Conservatives) becomes PM
                  </li>
                </ul>
              </Col>
              <Col sm={{ size: 4, offset: 0 }} className="role">
                <h3>Life:</h3>
                <ul>
                  <li>
                    Who even remembers the end of 2nd year/begnning of 3rd?
                  </li>
                </ul>
              </Col>
            </Row>
          </td>
          <td className="item">
            <h2 className="text-left">2011</h2>
            <Row>
              <Col sm={{ size: 4, offset: 0 }}>
                <h3>Top music:</h3>
                <ol>
                  <li>
                    <iframe
                      src="https://open.spotify.com/embed/track/1eq1wUnLVLg4pdEfx9kajC"
                      width="300"
                      height="80"
                      frameborder="0"
                      allowtransparency="true"
                      allow="encrypted-media"
                    />
                  </li>
                  <li>
                    <iframe
                      src="https://open.spotify.com/embed/track/1ve0SgTZkv3wdggJLqtBYU"
                      width="300"
                      height="80"
                      frameborder="0"
                      allowtransparency="true"
                      allow="encrypted-media"
                    />
                  </li>
                  <li>
                    <iframe
                      src="https://open.spotify.com/embed/track/4lCv7b86sLynZbXhfScfm2"
                      width="300"
                      height="80"
                      frameborder="0"
                      allowtransparency="true"
                      allow="encrypted-media"
                    />
                  </li>
                </ol>
              </Col>
              <Col sm={{ size: 4, offset: 0 }} className="role">
                <h3>News:</h3>
                <ul>
                  <li>Bin Laden shot &amp; killed by US forces</li>
                  <li>
                    EU agrees massive bailout for the Eurozone to control the
                    fallout from global recession
                  </li>
                  <li>
                    Arab Spring movement begins and takes roots across the arab
                    world
                  </li>
                </ul>
              </Col>
              <Col sm={{ size: 4, offset: 0 }} className="role">
                <h3>Life:</h3>
                <ul>
                  <li>Sat Junior Cert (yeesh)</li>
                  <li>Started T.Y.</li>
                  <li>Enjoyed some top tier puns</li>
                </ul>
              </Col>
            </Row>
          </td>
          <td className="item">
            <h2 className="text-left">2012</h2>
            <Row>
              <Col sm={{ size: 4, offset: 0 }}>
                <h3>Top music:</h3>
                <ol>
                  <li>
                    <iframe
                      src="https://open.spotify.com/embed/track/1qDrWA6lyx8cLECdZE7TV7"
                      width="300"
                      height="80"
                      frameborder="0"
                      allowtransparency="true"
                      allow="encrypted-media"
                    />
                  </li>
                  <li>
                    <iframe
                      src="https://open.spotify.com/embed/track/20I6sIOMTCkB6w7ryavxtO"
                      width="300"
                      height="80"
                      frameborder="0"
                      allowtransparency="true"
                      allow="encrypted-media"
                    />
                  </li>
                  <li>
                    <iframe
                      src="https://open.spotify.com/embed/track/5rgy6ghBq1eRApCkeUdJXf"
                      width="300"
                      height="80"
                      frameborder="0"
                      allowtransparency="true"
                      allow="encrypted-media"
                    />
                  </li>
                </ol>
              </Col>
              <Col sm={{ size: 4, offset: 0 }} className="role">
                <h3>News:</h3>
                <ul>
                  <li>North Korea successfully launch a rocket into orbit</li>
                  <li>Putin wins presidential election</li>
                  <li>Summer olympics in London</li>
                </ul>
              </Col>
              <Col sm={{ size: 4, offset: 0 }} className="role">
                <h3>Life:</h3>
                <ul>
                  <li>The world was suppose to end, right?</li>
                  <li>TY musical and all the associated antics</li>
                  <li>
                    Chillin' and then figuring out the whole "Leaving Cert"
                    thing
                  </li>
                </ul>
              </Col>
            </Row>
          </td>
          <td className="item">
            <h2 className="text-left">2013</h2>
            <Row>
              <Col sm={{ size: 4, offset: 0 }}>
                <h3>Top music:</h3>
                <ol>
                  <li>
                    <iframe
                      src="https://open.spotify.com/embed/track/4YMqbFcDIFiCBd02PzUBcM"
                      width="300"
                      height="80"
                      frameborder="0"
                      allowtransparency="true"
                      allow="encrypted-media"
                    />
                  </li>
                  <li>
                    <iframe
                      src="https://open.spotify.com/embed/track/0n4bITAu0Y0nigrz3MFJMb"
                      width="300"
                      height="80"
                      frameborder="0"
                      allowtransparency="true"
                      allow="encrypted-media"
                    />
                  </li>
                  <li>
                    <iframe
                      src="https://open.spotify.com/embed/track/62yJjFtgkhUrXktIoSjgP2"
                      width="300"
                      height="80"
                      frameborder="0"
                      allowtransparency="true"
                      allow="encrypted-media"
                    />
                  </li>
                </ol>
              </Col>
              <Col sm={{ size: 4, offset: 0 }} className="role">
                <h3>News:</h3>
                <ul>
                  <li>George, future King of GB is born</li>
                  <li>
                    Oscar Pistorius (Olympic runner) is arrested for murder of
                    his girlfriend{" "}
                  </li>
                  <li>Pope Francs is elected</li>
                </ul>
              </Col>
              <Col sm={{ size: 4, offset: 0 }} className="role">
                <h3>Life:</h3>
                <ul>
                  <li>5th year zzzzzz</li>
                </ul>
              </Col>
            </Row>
          </td>
          <td className="item">
            <h2 className="text-left">2014</h2>
            <Row>
              <Col sm={{ size: 4, offset: 0 }}>
                <h3>Top music:</h3>
                <ol>
                  <li>
                    <iframe
                      src="https://open.spotify.com/embed/track/6NPVjNh8Jhru9xOmyQigds"
                      width="300"
                      height="80"
                      frameborder="0"
                      allowtransparency="true"
                      allow="encrypted-media"
                    />
                  </li>
                  <li>
                    <iframe
                      src="https://open.spotify.com/embed/track/5jrdCoLpJSvHHorevXBATy"
                      width="300"
                      height="80"
                      frameborder="0"
                      allowtransparency="true"
                      allow="encrypted-media"
                    />
                  </li>
                  <li>
                    <iframe
                      src="https://open.spotify.com/embed/track/3U4isOIWM3VvDubwSI3y7a"
                      width="300"
                      height="80"
                      frameborder="0"
                      allowtransparency="true"
                      allow="encrypted-media"
                    />
                  </li>
                </ol>
              </Col>
              <Col sm={{ size: 4, offset: 0 }} className="role">
                <h3>News:</h3>
                <ul>
                  <li>
                    British parliament votes to give diplomatic recognition to
                    Palestine
                  </li>
                  <li>Russia hosts crazy expensive winter Olympics in Sochi</li>
                  <li>
                    Lots, and lots, and lots of post-Arab spring stuff in Eygpt.
                    Go read about it, I can't summerise it. It's crazy
                  </li>
                </ul>
              </Col>
              <Col sm={{ size: 4, offset: 0 }} className="role">
                <h3>Life:</h3>
                <ul>
                  <li>Sat the Leaving Cert (yeesh v2)</li>
                  <li>Graduated from secondary school</li>
                  <li>Moved to Dublin</li>
                  <li>Started PLC</li>
                  <li>Played many Dotas</li>
                  <li>Discovered fast internet</li>
                  <li>Scammed lots of free pizza</li>
                </ul>
              </Col>
            </Row>
          </td>
          <td className="item">
            <h2 className="text-left">2015</h2>
            <Row>
              <Col sm={{ size: 4, offset: 0 }}>
                <h3>Top music:</h3>
                <ol>
                  <li>
                    <iframe
                      src="https://open.spotify.com/embed/track/32OlwWuMpZ6b0aN2RZOeMS"
                      width="300"
                      height="80"
                      frameborder="0"
                      allowtransparency="true"
                      allow="encrypted-media"
                    />
                  </li>
                  <li>
                    <iframe
                      src="https://open.spotify.com/embed/track/34gCuhDGsG4bRPIf9bb02f"
                      width="300"
                      height="80"
                      frameborder="0"
                      allowtransparency="true"
                      allow="encrypted-media"
                    />
                  </li>
                  <li>
                    <iframe
                      src="https://open.spotify.com/embed/track/2JzZzZUQj3Qff7wapcbKjc"
                      width="300"
                      height="80"
                      frameborder="0"
                      allowtransparency="true"
                      allow="encrypted-media"
                    />
                  </li>
                </ol>
              </Col>
              <Col sm={{ size: 4, offset: 0 }} className="role">
                <h3>News:</h3>
                <ul>
                  <li>Charlie Hebdo terrorist attack in Paris</li>
                  <li>EU refugee crisis fully begins</li>
                  <li>November terrorist attacks across Paris</li>
                </ul>
              </Col>
              <Col sm={{ size: 4, offset: 0 }} className="role">
                <h3>Life things:</h3>
                <ul>
                  <li>Worked hard as hell</li>
                  <li>Remained a mystery</li>
                  <li>Played less Dota</li>
                  <li>Got your own room</li>
                  <li>Scammed slightly less pizza as a hobby</li>
                </ul>
              </Col>
            </Row>
          </td>
          <td className="item">
            <h2 className="text-left">2016</h2>
            <Row>
              <Col sm={{ size: 4, offset: 0 }}>
                <h3>Top music:</h3>
                <ol>
                  <li>
                    <iframe
                      src="https://open.spotify.com/embed/track/50kpGaPAhYJ3sGmk6vplg0"
                      width="300"
                      height="80"
                      frameborder="0"
                      allowtransparency="true"
                      allow="encrypted-media"
                    />
                  </li>
                  <li>
                    <iframe
                      src="https://open.spotify.com/embed/track/09CtPGIpYB4BrO8qb1RGsF"
                      width="300"
                      height="80"
                      frameborder="0"
                      allowtransparency="true"
                      allow="encrypted-media"
                    />
                  </li>
                  <li>
                    <iframe
                      src="https://open.spotify.com/embed/track/1zi7xx7UVEFkmKfv06H8x0"
                      width="300"
                      height="80"
                      frameborder="0"
                      allowtransparency="true"
                      allow="encrypted-media"
                    />
                  </li>
                </ol>
              </Col>
              <Col sm={{ size: 4, offset: 0 }} className="role">
                <h3>News:</h3>
                <ul>
                  <li>100 year anniversary of Irish Easter Rising</li>
                  <li>Brexit voted for</li>
                  <li>Brazil hosts summer Olympics in Rio de Janeiro</li>
                  <li>
                    There was a US presidental election. That's all I have to
                    say about that.
                  </li>
                </ul>
              </Col>
              <Col sm={{ size: 4, offset: 0 }} className="role">
                <h3>Life things:</h3>
                <ul>
                  <li>Finished PLC &amp; Started uni in UCD</li>
                  <li>Discovered the joy of uni Christmas exams</li>
                  <li>Got over Dominos as a hobby</li>
                </ul>
              </Col>
            </Row>
          </td>
          <td className="item">
            <h2 className="text-left">2017</h2>
            <Row>
              <Col sm={{ size: 4, offset: 0 }}>
                <h3>Top music:</h3>
                <ol>
                  <li>
                    <iframe
                      src="https://open.spotify.com/embed/track/7qiZfU4dY1lWllzX7mPBI3"
                      width="300"
                      height="80"
                      frameborder="0"
                      allowtransparency="true"
                      allow="encrypted-media"
                    />
                  </li>
                  <li>
                    <iframe
                      src="https://open.spotify.com/embed/track/6rPO02ozF3bM7NnOV4h6s2"
                      width="300"
                      height="80"
                      frameborder="0"
                      allowtransparency="true"
                      allow="encrypted-media"
                    />
                  </li>
                  <li>
                    <iframe
                      src="https://open.spotify.com/embed/track/0KKkJNfGyhkQ5aFogxQAPU"
                      width="300"
                      height="80"
                      frameborder="0"
                      allowtransparency="true"
                      allow="encrypted-media"
                    />
                  </li>
                </ol>
              </Col>
              <Col sm={{ size: 4, offset: 0 }} className="role">
                <h3>News:</h3>
                <ul>
                  <li>Trump under investigation for everything</li>
                  <li>
                    Enda Kenny steps down as Taoiseach and leader of Fine Gael.
                    Leo Veradkar takes over
                  </li>
                  <li>Bitcoin looked promising.</li>
                </ul>
              </Col>
              <Col sm={{ size: 4, offset: 0 }} className="role">
                <h3>Life:</h3>
                <ul>
                  <li>Did a great deal of things..</li>
                  <li>...and stuff</li>
                </ul>
              </Col>
            </Row>
          </td>
          <td className="item">
            <h2 className="text-left">2018</h2>
            <Row>
              <Col sm={{ size: 4, offset: 0 }}>
                <h3>Top music:</h3>
                <ol>
                  <li>
                    <iframe
                      src="https://open.spotify.com/embed/track/6DCZcSspjsKoFjzjrWoCdn"
                      width="300"
                      height="80"
                      frameborder="0"
                      allowtransparency="true"
                      allow="encrypted-media"
                    />
                  </li>
                  <li>
                    <iframe
                      src="https://open.spotify.com/embed/track/0tgVpDi06FyKpA1z0VMD4v"
                      width="300"
                      height="80"
                      frameborder="0"
                      allowtransparency="true"
                      allow="encrypted-media"
                    />
                  </li>
                  <li>
                    <iframe
                      src="https://open.spotify.com/embed/track/1rfofaqEpACxVEHIZBJe6W"
                      width="300"
                      height="80"
                      frameborder="0"
                      allowtransparency="true"
                      allow="encrypted-media"
                    />
                  </li>
                </ol>
              </Col>
              <Col sm={{ size: 4, offset: 0 }} className="role">
                <h3>News:</h3>
                <ul>
                  <li>Bitcoin falls, and falls, and continues to fall</li>
                  <li>Trump's assosciates go to jail, looks p. bad</li>
                  <li>Fortnight is the new fidget spinner</li>
                </ul>
              </Col>
              <Col sm={{ size: 4, offset: 0 }} className="role">
                <h3>Life:</h3>
                <ul>
                  <li>Remained a mystery</li>
                  <li>Entered final year of uni</li>
                  <li>Got that sweet home-office setup</li>
                  <li>That guitar, though</li>
                  <li>Rediscovered the joy (and pain) of dotka</li>
                  <li>Continued to be a great friend</li>
                </ul>
              </Col>
            </Row>
          </td>
          <td className="item">
            <h2 className="text-left">2019</h2>

            <div className="question-mark-container">
              <h3 className="question-mark">?</h3>
            </div>
          </td>

          <td className="item">
            <h3 className="text-left">While we're here...</h3>
            <br />
            <br />

            <div className="text-left">
              <iframe
                width="560"
                height="315"
                src="https://www.youtube.com/embed/nHc288IPFzk"
                frameborder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              />
            </div>

            <br />
            <br />

            <div>
              But,
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://youtu.be/dQw4w9WgXcQ"
              >
                Don't forget the classics
              </a>
            </div>

            <br />
            <br />

            <Link className="invite-plan" to={"/ryeen-day"}>
              {">"} See older Ryeen Days {"<"}
            </Link>
          </td>
        </tr>
      </table>
    );
  }
}

export default RyeenDay2018;
